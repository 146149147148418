<template>
  <div class="home"
       v-if="home">
    <div class="home__intro">
      <HomeHero :home="home" />
      <div class="home__marquee"
           v-if="home.marquee.showMarquee">
        <MarqueeText :repeat="7">
          <a v-if="home.marquee.marqueeLink"
             :target="home.marquee.marqueeLink.target"
             :href="home.marquee.marqueeLink.url">
            {{ home.marquee.marqueeText }}
          </a>
          <span v-if="!home.marquee.marqueeLink">
            {{ home.marquee.marqueeText }}
          </span>
        </MarqueeText>
      </div>
      <div class="mobile-headline">
        <h1>
          Stiftung
          <br />
          für Kunst, Kultur und Geschichte
        </h1>
      </div>
    </div>
    <div class="home__content">
      <section class="home__about">
        <h2 class="section-headline">{{ home.about.aboutTitle }}</h2>
        <p class="font-lg">
          {{ home.about.aboutText }}
        </p>
        <router-link to="/pages/stiftung"
                     class="link-arrow">
          {{ $t('about') }}
        </router-link>
      </section>
      <section class="home__links">
        <ul class="home__links__list">
          <li v-for="(link, i) in home.links"
              :key="'l' + i">
            <a v-if="link.link.type != 'entry'"
               :href="link.link.url"
               :target="link.link.target">
              <div class="home__links__imgwrap">
                <i :style="{
                  transform: `rotate(${Math.round(Math.random() * 360)}deg)`,
                }"></i>
                <HoverGif class="home__links__image"
                          v-if="link.image"
                          :gif="link.image" />
              </div>
              <span>{{ link.title }}</span>
            </a>
            <router-link v-else-if="link.link.entry != null"
                         :to="link.link.entry.uri"
                         :target="link.link.target">
              <div class="home__links__imgwrap">
                <i :style="{
                  transform: `rotate(${Math.round(Math.random() * 360)}deg)`,
                }"></i>
                <HoverGif class="home__links__image"
                          v-if="link.image"
                          :gif="link.image" />
              </div>
              <span>{{ link.title }}</span>
            </router-link>
          </li>
        </ul>
      </section>
      <section class="home__map"
               v-if="home.map.showMapSection">
        <h2 class="section-headline">Map</h2>
        <p class="font-xl">{{ home.map.mapLeadText }}</p>
        <p class="">
          {{ home.map.mapText }}
        </p>
        <div class="btn-wrap">
          <a class="btn btn--outline btn--inline"
             :href="home.map.mapLink.url"
             :target="home.map.mapLink.target">
            {{ home.map.mapLink.text }}
          </a>
        </div>

        <img :src="home.map.mapPreviewImage.url"
             class="home__map__img" />
      </section>
      <StickyHeart to="/pages/contact#newsletter">
        {{ $t('subscribeto') }}
      </StickyHeart>
    </div>
    <Footer />
  </div>
</template>

<script>
import SmoothScroll from 'smooth-scroll';

import HomeHero from '@/components/home/HomeHero.vue';
import EpisodePreview from '@/components/home/EpisodePreview.vue';
import StickyHeart from '@/components/home/StickyHeart.vue';
import Footer from '@/components/home/Footer.vue';
import HoverGif from '@/components/HoverGif.vue';
import MarqueeText from 'vue-marquee-text-component';

export default {
  name: 'Home',
  components: {
    MarqueeText,
    HomeHero,
    EpisodePreview,
    StickyHeart,
    Footer,
    HoverGif,
  },
  data: () => {
    return {
      activeEpisode: 1,
    };
  },
  beforeDestroy() {
    if (this.scroll) this.scroll.destroy();
  },
  watch: {
    loadingCount(to, from) {
      if (to == 0) {
        this.$nextTick(() => {
          this.handleHashNavigation();
        });
      }
    },
    $route: function (to, from) {
      this.handleHashNavigation();
    },
  },
  mounted() {
    this.$store.commit('resetFaviconUrl');

    this.scroll = new SmoothScroll();
    this.$nextTick(() => {
      this.handleHashNavigation();
    });
  },
  computed: {
    episodes() {
      return this.$store.state.episodes;
    },
    home() {
      return this.$store.state.home;
    },
    loadingCount() {
      return this.$store.state.loadingCount;
    },
  },
  methods: {
    handleHashNavigation() {
      if (!this.$route.hash) return;
      if (this.loadingCount) return;
      let hash = this.$route.hash;
      if (document.querySelector(hash)) {
        this.scroll.animateScroll(document.querySelector(hash), null, {
          offset: 100,
          speed: 100,
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/_helpers';

.home {
  &__intro {
    display: flex;
    flex-direction: column;

    @include bp-s() {
      flex-direction: column-reverse;
    }
  }

  &__marquee {
    background: #000;
    color: #fff;
    margin-bottom: 1rem;
    position: relative;
    z-index: 2;
    font-size: 1rem;
    -webkit-text-size-adjust: 100%;

    a,
    span {
      // font-size: 1rem !important;
      -webkit-text-size-adjust: 100%;
      // font-size: 11px !important;
    }

    .marquee-text-text {
      padding: 0.5rem 2.5rem 0.5rem 2.5rem;
      font-size: 1rem;

      &::after {
        content: '';
        width: 1.2rem;
        height: 1.2rem;
        display: block;
        position: absolute;
        top: 50%;
        right: -0.6rem;
        margin-top: -0.6rem;
        background: url('../assets/heart-marquee.svg') no-repeat center;
        background-size: 100% auto;
        margin-left: 2rem;
      }
    }

    @include bp-s() {
      margin-bottom: 0;
    }
  }

  &__content {
    padding: 0.75rem;
    box-sizing: border-box;
    position: relative;

    @include bp-s() {
      padding: 0.5rem;
    }
  }

  &__about {
    max-width: 35.5rem;
    margin-bottom: 7rem;

    p {
      font-weight: bold;
    }

    @include bp-s() {
      margin-bottom: 5rem;
    }
  }

  &__episodes {
    margin: 7rem 0;

    &__list {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: 0.3rem;
    }

    p {
      width: 50%;
    }

    @include bp-s() {
      margin: 5rem 0;

      p {
        margin-top: 0.5rem;
        width: 100%;
        max-width: 600px;
      }
    }

    @include bp-xs() {
      &__list {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        grid-gap: 0;
      }
    }
  }

  &__links {
    margin: 5rem 0;

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      width: 16.66%;
      margin: 0.5rem 0;
      padding: 0 0.5rem;
      box-sizing: border-box;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:hover {
        .home__links__imgwrap {
          // transform: scale(1.02);
        }
      }
    }

    span {
      font-weight: bold;
      text-align: center;
    }

    &__image {
      position: absolute;
      top: 10%;
      left: 10%;
      width: 80%;
      height: 80%;
      z-index: 1;
    }

    &__imgwrap {
      position: relative;
      width: 80%;
      height: 0;
      padding-bottom: 80%;
      margin-bottom: 0.5rem;
      will-change: transform;
      transition: 0.3s transform $easeOut;

      i {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('../assets/home-link-bg.svg');
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 0;
        border-radius: 100%;
      }
    }

    @include bp-m() {
      li {
        width: 25%;
      }
    }

    @include bp-s() {
      margin: 3rem 0;

      li {
        width: 33.33%;
      }
    }

    @include bp-xs() {
      li {
        width: 50%;
      }
    }
  }

  &__map {
    &__img {
      margin-left: -0.8rem;
      width: calc(100% + 1.5rem);
      height: 18rem;
      object-fit: cover;
    }

    p {
      width: 50%;
      margin-top: 0.5rem;
    }

    p.font-xl {
      width: 75%;
    }

    @include bp-s() {
      p {
        width: 100%;
        max-width: 600px;
      }
    }
  }
}</style>