<template>
  <div id="app">
    <Header />
    <Navigation />
    <MobileMenuToggle />
    <keep-alive>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </keep-alive>
  </div>
</template>

<script>
import Header from '@/components/Header';
import MobileMenu from '@/components/MobileMenu';
import Navigation from '@/components/Navigation';
import MobileMenuToggle from '@/components/MobileMenuToggle';

export default {
  name: 'Home',
  components: {
    Header,
    MobileMenu,
    Navigation,
    MobileMenuToggle,
  },
  data: () => {
    return {};
  },
  metaInfo() {
    return {
      title: this.getTitle,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.getDescription,
        },
        { vmid: 'ogimage', name: 'og:image', content: this.getShareImage.url },
        {
          vmid: 'ogwidth',
          name: 'og:width',
          content: this.getShareImage.width,
        },
        {
          vmid: 'ogheight',
          name: 'og:height',
          content: this.getShareImage.height,
        },
        { vmid: 'ogurl', name: 'og:url', content: this.pageUrl },
      ],
    };
  },
  watch: {
    activeLang() {
      this.$store.commit('setApiUrl');
      this.$store.dispatch('loadInitialData');
    },
    faviconUrl() {
      let fav = document.querySelector('#favicon');
      fav.href = this.faviconUrl;
    },
    navVisible() {
      if (this.navVisible) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  },
  mounted() {
    this.$store.commit('setLanguage', this.$i18n.locale);
  },
  computed: {
    getTitle() {
      if (this.globalData) return this.globalData.siteTitle;
      return 'campo';
    },
    getDescription() {
      if (this.globalData) return this.globalData.siteDescription;
      return '';
    },
    getShareImage() {
      if (this.globalData) return this.globalData.shareImage;
      return { url: '', width: '', height: '' };
    },
    pageUrl() {
      return process.env.VUE_APP_SITEURL + this.$route.fullPath;
    },
    activeLang() {
      return this.$store.state.activeLang;
    },
    faviconUrl() {
      return this.$store.state.faviconUrl;
    },
    globalData() {
      return this.$store.state.globalData;
    },
    mobileMenuVisible() {
      return this.$store.state.mobileMenuVisible;
    },
    navVisible() {
      return this.$store.state.navVisible;
    },
  },
  methods: {
    checkLocalLang() {
      let localLang = window.localStorage.getItem('SKKG_LANG');
      if (localLang == 'fr' || localLang == 'de') {
        this.$store.dispatch('changeLanguage', {
          lang: localLang,
          i18n: this.$i18n,
        });
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  padding-top: var(--header-height);
  box-sizing: border-box;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
